import { Temporal } from "temporal-polyfill";
import { useGetPatientServingDTOs } from "../../../../../../../../components/cgm-google-chart/hooks/use-get-patient-serving-dtos";
import { useMemo } from "react";
import {
  NutritionSummaryCardHeader,
  NutritionSummaryContainer,
} from "../styled-nutrition-summary";
import { FatsSummary } from "../nutrition-summary-footer/fats-summary";
import { MacrosSummary } from "../nutrition-summary-footer/macros-summary";
import { calculateOtidaCategoriesServingsCounts } from "../utils/servings-utils";
import { OTIDA_CATEGORIES_NAMES } from "../utils/otida-categories-data";
import {
  NutritionSummaryCardCenter,
  NutritionSummaryCardCenterList,
  NutritionSummaryCardCenterListItem,
} from "../nutrition-summary-center/styled-nutrition-summary-center";
import { roundTo1DecimalPlace } from "../../../../../../../../utils/math-utils";

type StaticNutritionSummaryProps = {
  date: Temporal.PlainDate;
};

export const StaticNutritionSummary = (props: StaticNutritionSummaryProps) => {
  const { date } = props;

  const { patientServingDTOs } = useGetPatientServingDTOs();

  const datePatientServingDTOs = useMemo(
    () =>
      patientServingDTOs.filter((patientServingDTO) =>
        Temporal.PlainDate.from(patientServingDTO.date).equals(date)
      ),
    [date, patientServingDTOs]
  );

  const categoryToServingCount = useMemo(
    () => calculateOtidaCategoriesServingsCounts(datePatientServingDTOs),
    [datePatientServingDTOs]
  );

  const categoriesWithServings = OTIDA_CATEGORIES_NAMES.map(
    (otidaCategoryName) => (
      <NutritionSummaryCardCenterListItem key={otidaCategoryName}>
        <span>
          {roundTo1DecimalPlace(categoryToServingCount[otidaCategoryName] ?? 0)}
        </span>
        <p>{otidaCategoryName}</p>
      </NutritionSummaryCardCenterListItem>
    )
  );

  const left = categoriesWithServings.slice(0, 5);
  const right = categoriesWithServings.slice(5, 10);

  return (
    <NutritionSummaryContainer>
      <NutritionSummaryCardHeader>
        <h3>Nutrition summary</h3>
        <p>Food groups</p>
      </NutritionSummaryCardHeader>
      <NutritionSummaryCardCenter>
        <NutritionSummaryCardCenterList>
          <div>{left}</div>
          <div>{right}</div>
        </NutritionSummaryCardCenterList>
      </NutritionSummaryCardCenter>
      <>
        <FatsSummary datePatientServingDTOs={datePatientServingDTOs} />
        <MacrosSummary datePatientServingDTOs={datePatientServingDTOs} />
      </>
    </NutritionSummaryContainer>
  );
};
