import { PatientServingDTO } from "../../../../../../../../models/patient-serving-dto";
import { useMemo } from "react";
import {
  calculateFatsSummary,
  isGoodSaturatedFatsPercentage,
  isGoodTransFatsPercentage,
} from "./utils/fats-summary-utils";
import { roundTo1DecimalPlace } from "../../../../../../../../utils/math-utils";
import {
  FatSummaryContainer,
  FatSummaryText,
} from "./styled-nutrition-summary-footer";

export type FatsSummaryProps = {
  datePatientServingDTOs: PatientServingDTO[];
};

export function FatsSummary(props: FatsSummaryProps) {
  const { datePatientServingDTOs } = props;

  const { saturatedFatsPercentage, transFatsPercentage } = useMemo(
    () => calculateFatsSummary(datePatientServingDTOs),
    [datePatientServingDTOs]
  );

  return (
    <FatSummaryContainer>
      <FatSummaryText
        isGood={isGoodSaturatedFatsPercentage(saturatedFatsPercentage)}
      >
        Saturated fats:{" "}
        <span>{roundTo1DecimalPlace(saturatedFatsPercentage)}%</span>
      </FatSummaryText>
      <FatSummaryText isGood={isGoodTransFatsPercentage(transFatsPercentage)}>
        Trans fats: <span>{roundTo1DecimalPlace(transFatsPercentage)}%</span>
      </FatSummaryText>
    </FatSummaryContainer>
  );
}
